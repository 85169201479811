import * as React from 'react';
import { Container } from 'reactstrap';

import reinvestmentData from '../data/reinvestment.json';
import { BaseLayout, Markdown } from '../components';

export const ReinvestmentPageInternal = ({ header, mainMarkdown }: typeof reinvestmentData) => {
  return (
    <BaseLayout title="Community Reinvestment Notice">
      <Container className="text-center mt-4 child-spacing-y-4">
        <h1>{header}</h1>

        <Markdown source={mainMarkdown} />
      </Container>
    </BaseLayout>
  );
};

export default () => (
  <ReinvestmentPageInternal {...reinvestmentData} />
);
